<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}生产单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="生产配置" prop="goodsSpecification.id">
        <select-maintain
          :model-value="{ goodsSpecification: form.data.goodsSpecification }"
          :ajax="{ action: 'GET /enocloud/manufacturing/material', params: (params, quickSearch) => (params.payload = { quickSearch }) }"
          :props="{ label: 'goodsSpecification.goods.name', value: '' }"
          value-key="goodsSpecification.id"
          remote
          :disabled="form.disabled"
          class="w-full"
          @change="form.goodsSpecification.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="配件编码">
        <en-input :model-value="form.data.goodsSpecification?.goods?.serialNo" disabled></en-input>
      </en-form-item>
      <en-form-item label="配件名称">
        <en-input :model-value="form.data.goodsSpecification?.goods?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="配件规格">
        <en-input :model-value="form.data.goodsSpecification?.name" disabled></en-input>
      </en-form-item>
      <en-form-item label="生产仓库" prop="materialWarehouse.id">
        <select-maintain
          v-model="form.data.materialWarehouse"
          :ajax="{ action: 'GET /enocloud/common/warehouse', params: (params, name) => (params.payload = { name }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="入库仓库" prop="productWarehouse.id">
        <select-maintain
          v-model="form.data.productWarehouse"
          :ajax="{ action: 'GET /enocloud/common/warehouse', params: (params, name) => (params.payload = { name }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="生产数量">
        <en-input-number v-model="form.data.count" :disabled="form.disabled" class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="配件总金额" v-if="form.disabled">
        <en-input-number :model-value="form.data.primeCost" disabled class="w-full"></en-input-number>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment" :disabled="form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :disabled="form.disabled" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudManufacturingDefinitions['ManufacturingProductionDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        this.form.init()
        if (this.modelValue) {
          this.form.data.id = this.data?.id
          if (this.form.data.id) this.form.get()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'goodsSpecification.id': [{ required: true, message: '请选择生产配置' }],
          'materialWarehouse.id': [{ required: true, message: '请选择生产仓库' }],
          'productWarehouse.id': [{ required: true, message: '请选择入库仓库' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/manufacturing/production/:productionId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/manufacturing/production',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/manufacturing/production',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return this.form.data.status?.code === 'CP'
          }
        },
        children: {
          goodsSpecification: {
            change(value: EnocloudManufacturingDefinitions['ManufacturingMaterialDto']) {
              this.form.data.goodsSpecification = value.goodsSpecification
              this.form.data.goods = value.manufacturingMaterialGoods.filter(
                (item) => item.enabled?.value
              ) as EnocloudManufacturingDefinitions['ManufacturingProductionGoodsDto'][]
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
